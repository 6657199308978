import { ChevronRight } from 'lucide-react'
import { useState } from 'react'

import { Transaction as TransactionProps } from '@/api/hooks/use-transaction.ts'
import { cn } from '@/lib/utils.ts'
import { ACH_RETURN_CODE } from '@/utils/codes'
import { formatCentsToCurrency } from '@/utils/money-utils'

type Props = TransactionProps & {
	displayDivider?: boolean
}

export function Transaction({
	amount,
	purchase_date,
	business_name,
	last_four,
	status,
	public_id,
	has_retried,
	retries,
}: Props) {
	const [isRetriesExpanded, setIsRetriesExpanded] = useState(false)

	return (
		<div
			className={cn(
				'flex flex-col justify-center border-t border-t-primary-100 px-2 py-md',
				!has_retried && 'h-[84px]',
			)}
		>
			<div
				className="grid grid-cols-[1fr_auto] items-center"
				data-testid={`transaction-${public_id}-item`}
			>
				<div className="flex flex-col justify-center">
					<span
						className="typography-label-small font-medium text-neutral-800"
						data-testid={`transaction-${public_id}-date`}
					>
						{new Date(purchase_date).toDateString()}
					</span>
					<span
						className="typography-label-large font-medium text-primary-900"
						data-testid={`transaction-${public_id}-business`}
					>
						{business_name}
					</span>
					<div className="typography-label-small flex items-end font-medium text-neutral-800">
						<span>Transaction No.</span>
						<span
							className="ml-1"
							data-testid={`transaction-${public_id}-number`}
						>
							{public_id}
						</span>
					</div>
					<div className="typography-label-small flex items-end font-medium text-neutral-800">
						<span>Account No.</span>
						<span
							className="ml-1"
							data-testid={`transaction-${public_id}-account`}
						>
							{last_four}
						</span>
					</div>
				</div>
				<div className="flex flex-col items-end justify-center text-right">
					<span
						className={cn(
							'typography-body-large font-normal',
							status === 'Pending' ? 'text-neutral-700' : 'text-primary-900',
						)}
						data-testid={`transaction-${public_id}-amount`}
					>
						{formatCentsToCurrency(amount)}
					</span>
					<span
						className={cn(
							'typography-label-medium-prominent font-semibold capitalize',
							status === 'Pending' ? 'text-neutral-700' : 'text-primary-900',
						)}
						data-testid={`transaction-${public_id}-status`}
					>
						{status}
					</span>
				</div>
			</div>
			{has_retried && (
				<div className="">
					<button
						onClick={() => setIsRetriesExpanded(!isRetriesExpanded)}
						className="typography-label-medium-prominent mt-2 flex items-center font-semibold text-primary"
					>
						<span className={cn('transition-transform duration-200', isRetriesExpanded ? 'rotate-90' : 'rotate-0')}>
							<ChevronRight size={16} />
						</span>
						{retries?.length || 0} Retries
					</button>
					<div
						className={cn(
							'origin-top overflow-hidden transition-all duration-200 ease-in-out',
							isRetriesExpanded ? 'max-h-none scale-y-100 opacity-100' : 'max-h-0 scale-y-95 opacity-0',
						)}
					>
						<div className="pb-md pl-7 pt-sm">
							{(retries || []).map((retry) => (
								<div
									key={retry.id}
									className="flex items-center justify-between"
								>
									<div className="flex flex-col">
										<span className="typography-label-small font-medium text-neutral-800">
											{new Date(retry.created_at).toDateString()}
										</span>
										<span className="typography-label-medium font-medium text-primary-700">
											{ACH_RETURN_CODE[retry.code as keyof typeof ACH_RETURN_CODE] || '-'}
										</span>
									</div>
									<div className="flex items-center justify-between">
										<span
											className={cn(
												'text-sm font-medium capitalize',
												retry.status === 'Failed' && 'text-red-600',
												retry.status === 'Pending' && 'text-orange-600',
												retry.status === 'Posted' && 'text-green-600',
											)}
										>
											{retry.status}
										</span>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
