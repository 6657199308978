import { Route } from 'react-router-dom'

import AuthenticationGuard from '@/components/authentication-guard'
import { MyRole } from '@/constants/my-role'
import { ConsumerLayout } from '@/layout/consumer/ConsumerLayout'
import { AccountsPage } from '@/pages/consumer/accounts/accounts-page'
import { LinkAccountErrorPage } from '@/pages/consumer/accounts/link-account-error-page'
import { LinkAccountPage } from '@/pages/consumer/accounts/link-account-page'
import { DashboardPage } from '@/pages/consumer/dashboard/dashboard-page'
import { RelinkRequired } from '@/pages/consumer/dashboard/relink-required-page'
import { TransactionHistory } from '@/pages/consumer/dashboard/transaction-history-page'
import { EcommercePurchasePage } from '@/pages/consumer/ecommerce-page'
import { PaymentCancelled } from '@/pages/consumer/payment/payment-cancelled'
import { PaymentConfirm } from '@/pages/consumer/payment/payment-confirm-page'
import { PaymentFail } from '@/pages/consumer/payment/payment-fail-page'
import { PaymentPage } from '@/pages/consumer/payment/payment-page'
import { PaymentSuccessPage } from '@/pages/consumer/payment/payment-success-page'
import { ContactSupport } from '@/pages/consumer/settings/contact-support-page'
import { DeactivateAccount } from '@/pages/consumer/settings/deactivate-account-page'
import { PersonalInformation } from '@/pages/consumer/settings/personal-information-page'
import { SettingsPage } from '@/pages/consumer/settings/settings-page'

export const consumerRoutes = () => {
	return (
		<>
			<Route
				path="/dashboard"
				element={<ConsumerLayout />}
			>
				<Route
					index
					element={
						<AuthenticationGuard
							component={DashboardPage}
							requiredRoles={[MyRole.Consumer]}
						/>
					}
				/>
				<Route path="accounts">
					<Route
						index
						element={
							<AuthenticationGuard
								component={AccountsPage}
								requiredRoles={[MyRole.Consumer]}
							/>
						}
					/>
				</Route>
				<Route
					path="transactions-history"
					element={
						<AuthenticationGuard
							component={TransactionHistory}
							requiredRoles={[MyRole.Consumer]}
						/>
					}
				/>
				<Route path="settings">
					<Route
						index
						element={<AuthenticationGuard component={SettingsPage} />}
					/>
					<Route
						path="personal-information"
						element={<AuthenticationGuard component={PersonalInformation} />}
					/>
					<Route
						path="contact-support"
						element={<AuthenticationGuard component={ContactSupport} />}
					/>
					<Route
						path="deactivate-account"
						element={<AuthenticationGuard component={DeactivateAccount} />}
					/>
				</Route>
			</Route>

			<Route
				path="/dashboard/relink-required"
				element={
					<AuthenticationGuard
						component={RelinkRequired}
						requiredRoles={[MyRole.Consumer]}
					/>
				}
			/>

			<Route path="/payment">
				<Route
					index
					element={
						<AuthenticationGuard
							component={PaymentPage}
							requiredRoles={[MyRole.Consumer]}
						/>
					}
				/>
				<Route
					path="confirmation"
					element={
						<AuthenticationGuard
							component={PaymentConfirm}
							requiredRoles={[MyRole.Consumer]}
						/>
					}
				/>
				<Route
					path="success"
					element={
						<AuthenticationGuard
							component={PaymentSuccessPage}
							requiredRoles={[MyRole.Consumer]}
						/>
					}
				/>
				<Route
					path="cancelled"
					element={
						<AuthenticationGuard
							component={PaymentCancelled}
							requiredRoles={[MyRole.Consumer]}
						/>
					}
				/>
				<Route
					path="error"
					element={
						<AuthenticationGuard
							component={PaymentFail}
							requiredRoles={[MyRole.Consumer]}
						/>
					}
				/>
			</Route>

			<Route
				path="/link-account"
				element={
					<AuthenticationGuard
						component={LinkAccountPage}
						requiredRoles={[MyRole.Consumer]}
					/>
				}
			/>
			<Route
				path="/link-account-error"
				element={
					<AuthenticationGuard
						component={LinkAccountErrorPage}
						requiredRoles={[MyRole.Consumer]}
					/>
				}
			/>
			<Route
				path="ecommerce-purchase"
				element={
					<AuthenticationGuard
						component={EcommercePurchasePage}
						requiredRoles={[MyRole.Consumer]}
					/>
				}
			/>
		</>
	)
}
